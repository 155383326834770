<template>
  <div class="index">
    <!-- 睡眠中心 -->
    <div class="centreTitle">
      <p>睡眠中心</p>
      <span>Sleep center</span>
    </div>
    <div>
      <div>
        <div class="Prompt">
          <p>中心介绍</p>
          <span>Center introduction</span>
        </div>
        <div class="content">
          <span>
            睡眠中心专注于解决各个年龄层次人群的睡眠问题。结合国内外诊疗指南，循证医学证据和专家数十年临床治疗经验，结合全面的睡眠相关评估，严谨、细致、科学地解决睡眠问题。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>服务介绍</p>
          <span>Service introduction</span>
        </div>
        <div class="content">
          <p>评估检查：<span>专业医师团队对您的主观睡眠问题开展详细的问诊和评估，了解睡眠问题背后的各种因素。
            </span> </p>
          <p>专科检查：<span>结合专业检查，对您的实际睡眠情况开展评估，以进一步明确病因，以便精准化地针对睡眠问题开展治疗。
            </span> </p>
          <p>医学干预：<span>结合各项评估，分析导致睡眠问题的各项因素，制定个性化的睡眠干预方式，治标治本。
            </span> </p>
          <p>心理干预：<span>CBTi（睡眠认知行为疗法）和正念治疗，帮助改善睡眠焦虑，让您不再为睡眠问题感到困扰。
            </span> </p>
          <p>物理治疗：<span>针对疾病症状关联度高的特定脑区，通过长期反馈训练、重复电磁刺激等无痛无创无介入的方式，帮助恢复脑区的异常活动，快速缓解疾病。
            </span> </p>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                  item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '朱昌明', imgPath: 'export (5).png', memo: '擅长各类精神病的防止治问题,老年精神障碍、酒、药物、网络问题的诊断，心理咨询。', titleList: [{ id: 1, title: '四川大学华西医院心理卫生中心' }, { id: 2, title: '终身教授' }, { id: 3, title: '著名精神病学专家' }] },

        { id: 2, name: '高哲', imgPath: 'export (3).png', memo: '擅长精神障碍的诊治，以及焦虑抑郁等情绪问', titleList: [{ id: 1, title: '医师硕士' }, { id: 2, title: '精神科主治医生' }, { id: 3, title: '心理治疗师' }] },

        { id: 3, name: '张丽芳', imgPath: 'export (2).png', memo: '擅长儿童及青少年的情绪管理，青春期心理适应，儿童及青少年家庭教育，亲子团体。成人焦虑、抑郁、强迫等心理问题。', titleList: [{ id: 1, title: '国家二级心理咨询师' }, { id: 2, title: '中国科学院心理研究所研究生' }] },

        { id: 4, name: '刘萍', imgPath: 'export (4).png', memo: '擅长运用认知行为疗法、箱庭疗法、家庭治疗、意象对话来进行情绪管理、亲密关系，以及青少年问题的咨询。', titleList: [{ id: 1, title: '国家注册心理咨询师' }, { id: 2, title: '情绪管理师' }, { id: 3, title: '父母教养指导师' }] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');
</style>