<template>
  <div class="index">
    <!-- 个人成长中心-->
    <div class="centreTitle">
      <p>个人成长中心</p>
      <span>Center for personal growth</span>
    </div>
    <div>
      <div class="first">
        <div class="content">
          <span>
            个人成长是一个终其一生，没有完结的过程。我不够幸福快乐？我缺乏动机？我选择困难？我不能持之以恒？这些问题都和个人成长有关。克己、拖延、习惯养成、动机、完美主义、成长型思维，这些都会在个人成长中心中阅读到。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                      item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '徐云', imgPath: 'export (12).png', memo: '毕业于四川大学，研究方向为精神分析与心理治疗，采用精神分析对心境障碍、焦虑障碍等治疗。', titleList: [{ id: 1, title: '副教授、硕士生导师' }, { id: 2, title: '心理治疗师' }] },

        { id: 2, name: '刘悦', imgPath: 'export (10).png', memo: '运用精神分析进行情绪、婚姻家庭、人家关系、亲子关系、自我成长以及职场问题的心理咨询。', titleList: [{ id: 1, title: '四川大学应用心理学硕士' }, { id: 2, title: '国家二级心理咨询师' }, { id: 3, title: '美国NGH注册催眠师' }] },



        { id: 3, name: '毛晓雨', imgPath: 'export (11).png', memo: '擅长运用认知行为治疗技术、绘画治疗技术、沙盘治疗技术及短程焦点治疗，为来访者提供成长和帮助。', titleList: [{ id: 1, title: '心理医师，应用学心理硕士' }, { id: 2, title: '成都朗德教育心理成长专家' }, ] },

        { id: 4, name: '余良明', imgPath: 'export (19).png', memo: '擅长成人目标导向的心理咨询、精神疾病康复、焦虑抑郁的分析和治疗。尤其是青少年儿童成长的心理咨询，行为问题的诊疗。LGBT友好。', titleList: [{ id: 1, title: '医学硕士' }, { id: 2, title: '精神科医师' }, { id: 3, title: '心理治疗师' }, 
        { id: 4, title: '心理咨询师' },] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');

.index {
  .first {
    margin-top: 15px;
  }
}
</style>