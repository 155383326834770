import { render, staticRenderFns } from "./Relationship.vue?vue&type=template&id=13519aa3&scoped=true&"
import script from "./Relationship.vue?vue&type=script&lang=js&"
export * from "./Relationship.vue?vue&type=script&lang=js&"
import style0 from "./Relationship.vue?vue&type=style&index=0&id=13519aa3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13519aa3",
  null
  
)

export default component.exports