<template>
  <div class="index">
    <!-- 认知障碍中心 -->
    <div class="centreTitle">
      <p>认知障碍中心</p>
      <span>Cognitive impairment center</span>
    </div>
    <div>
      <div class="Prompt">
        <p>中心介绍</p>
        <span>Center introduction</span>
      </div>
      <div class="content">
        <span>
          认知障碍中心专注于中老年人群的认知功能障碍问题。包括了轻度认知功能障碍、各类痴呆（阿尔茨海默病、血管性痴呆）等。这些功能障碍常常伴有行为和情感的异常，导致病人日常生活、社会交往和工作能力的明显减退。认知障碍中心提供专业的医疗干预和评估，心理治疗和物理治疗，并在康复活动中指导照料者如何应对认知功能障碍中的各种症状及行为异常，减缓认知功能障碍的发展进度，提升患者的社会功能，降低照料者的照料难度。
        </span>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>服务介绍</p>
        <span>Service introduction</span>
      </div>
      <div class="content">
        <p>评估检查：<span>专业医师团队对认知功能问题开展详细的问诊和评估，了解问题的症状表现，患者的生活背景、照料环境、家庭、社会支持系统等。
          </span> </p>
          <p>医学干预：<span>结合各项评估，制定个性化的干预方式，针对患者致病因素提出医疗建议和治疗方案。
          </span> </p>
          <p>心理干预：<span>通过心理咨询与治疗，改进对于患者的情绪问题，获得更多福祉。
          </span> </p>
          <p>物理治疗：<span>针对疾病症状关联度高的特定脑区，通过长期反馈训练、重复电磁刺激等无痛无创无介入的方式，帮助恢复脑区的异常活动，快速缓解疾病。
          </span> </p>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>专家团体</p>
        <span>Expert introduction</span>
      </div>
      <div>
        <div class="export">
          <div class="item" v-for="item in exportList" :key="item.id">
            <div class="left">
              <span>{{ item.name }}</span>
              <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
              <div v-else class="noImg"></div>
            </div>
            <div class="right">
              <div class="title">
                <p v-for="i in
                item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
              </div>
              <p>{{ item.memo }}</p>
            </div>
            <div class="bottomStyle"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>案例介绍</p>
        <span>Case introduction</span>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return{
      exportList: [
      { id: 1, name: '谢靓', imgPath: 'export (14).png', memo: '擅长青少年儿童情绪障碍的诊治、各类成年精神疾病的诊治，成瘾相关疾病的诊断和治疗。', titleList: [{ id: 1, title: '四川省预防医学会未成年人心理健康分会会员' }, { id: 2, title: '成都榕爱社会服务工作中心副理事长' }, { id: 3, title: '社会心理干预分会委员兼总干事' }] },

        {id: 2, name: '郭菁', imgPath: 'export (17).png', memo: '擅长儿童青少年情绪障碍、多动症、孤独谱系障碍及老年人焦虑症、抑郁症等疾病的诊断和治疗，系统培训认知行为治疗、动机式访谈的心理咨询治疗。', titleList: [{id:1, title: '医学博士'}, {id:2, title: '精神科主治医师'}, {id: 3, title: '心理治疗师'}]},
         { id: 3, name: '余良明', imgPath: 'export (19).png', memo: '擅长目标导向的心理咨询、精神疾病康复、焦虑抑郁的分析与治疗、青少年成长及性心理咨询。', titleList: [{ id: 1, title: '性少数友好咨询师' }, { id: 2, title: '心理咨询师' }] },
         { id: 4, name: '毛晓雨', imgPath: 'export (11).png', memo: '擅长运用认知行为治疗技术、绘画治疗技术、沙盘治疗技术及短程焦点治疗，为来访者提供成长和帮助。', titleList: [{ id: 1, title: '心理医师，应用学心理硕士' }, { id: 2, title: '成都朗德教育心理成长专家' }, { id: 3, title: '四川省应用心理学研究中心研究员' }] },
      ],
      
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');
</style>