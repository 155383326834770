<template>
  <div class="index">
    <!-- 成瘾中心 -->
    <div class="centreTitle">
      <p>成瘾中心</p>
      <span>Addiction center</span>
    </div>
    <div>
      <div class="Prompt">
        <p>中心介绍</p>
        <span>Center introduction</span>
      </div>
      <div class="content">
        <span>
          成瘾中心专注于解决各类物质及行为成瘾的问题。成瘾中心拥有国内成瘾方面的知名专家团队，结合国内外诊疗指南，循证医学证据和专家数十年临床治疗经验，结合全面的成瘾相关评估，严谨、细致、科学地干预成瘾问题。
        </span>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>服务介绍</p>
        <span>Service introduction</span>
      </div>
      <div class="content">
        <p>评估检查：<span>专业医师团队对成瘾相关问题开展详细的问诊和评估，了解睡眠问题背后的各种因素。
          </span> </p>
          <p>医学干预：<span>结合各项评估，分析导致成瘾问题的各项因素，制定个性化的干预方式。
          </span> </p>
          <p>心理干预：<span>通过心理治疗地方式改善成瘾问题的症状、提高戒瘾动机以及应对渴求的技巧。
          </span> </p>
          <p>物理治疗：<span>针对疾病症状关联度高的特定脑区，通过长期反馈训练、重复电磁刺激等无痛无创无介入的方式，帮助恢复脑区的异常活动，快速缓解疾病。
          </span> </p>
          <p>戒烟项目：<span>开展线上和线下相结合的戒烟整体干预方案。
          </span> </p>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>专家团体</p>
        <span>Expert introduction</span>
      </div>
      <div>
        <div class="export">
          <div class="item" v-for="item in exportList" :key="item.id">
            <div class="left">
              <span>{{ item.name }}</span>
              <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
              <div v-else class="noImg"></div>
            </div>
            <div class="right">
              <div class="title">
                <p v-for="i in
                item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
              </div>
              <p>{{ item.memo }}</p>
            </div>
            <div class="bottomStyle"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>案例介绍</p>
        <span>Case introduction</span>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return{
      exportList: [
        {id: 1, name: '黄明生', imgPath: 'export (20).png', memo: '擅长各类精神病的防止治问题,老年精神障碍、酒、药物、网络问题的诊断，心理咨询。', titleList: [{id:1, title: '四川大学华西医院心理卫生中心'}, {id:2, title: '终身教授  博士生导师'}, {id: 3, title: '精神药理学和戒毒专业领军人物'}]},
        {id: 2, name: '胡珉', imgPath: 'export (1).png', memo: '擅长青少年儿童情绪障碍的诊治、各类成年精神疾病的诊治，成瘾相关疾病的诊断和治疗。', titleList: [{id:1, title: '医师硕士'}, {id:2, title: '精神科副主任医师'}, {id: 3, title: '心理治疗师'}]},
        {id: 3, name: '高哲', imgPath: 'export (3).png', memo: '擅长精神障碍的诊治，以及焦虑抑郁等情绪问', titleList: [{id:1, title: '医师硕士'}, {id:2, title: '精神科主治医生'}, {id: 3, title: '心理治疗师'}]},
        { id: 4, name: '毛晓雨', imgPath: 'export (11).png', memo: '擅长运用认知行为治疗技术、绘画治疗技术、沙盘治疗技术及短程焦点治疗，为来访者提供成长和帮助。', titleList: [{ id: 1, title: '心理医师，应用学心理硕士' }, { id: 2, title: '成都朗德教育心理成长专家' }, ] },
        // {id: 4, name: '张丽芳', imgPath: 'export (2).png', memo: '擅长儿童及青少年的情绪管理，青春期心理适备份', titleList: [{id:1, title: '国家二级心理咨询师'}, {id:2, title: '中国科学院心理研究所研究生'}]},
      ],
      
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');
</style>