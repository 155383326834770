<template>
  <div class="index">
    <!-- 学习困难中心 -->
    <div class="centreTitle">
      <p>学习困难中心</p>
      <span>Learning difficulties centre</span>
    </div>
    <div>
      <div>
        <div class="Prompt">
          <p>中心介绍</p>
          <span>Center introduction</span>
        </div>
        <div class="content">
          <span>
            学习困难中心专注于解决儿童青少年的学习困难问题。包括了孤独谱系障碍和注意力缺陷多动障碍、学习障碍，儿童青少年期的学习焦虑问题。结合国内外诊疗指南，循证医学证据和专家数十年临床治疗经验，针对孩子的症状表现，孩子的成长环境、家庭教养方式等开展全面的评估，严谨、细致、科学地处理孩子的学习困难问题。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>服务介绍</p>
          <span>Service introduction</span>
        </div>
        <div class="content">
          <p>评估检查：<span>专业医师团队对孩子的学习困难问题开展详细的问诊和评估，了解孩子的症状表现，孩子的成长环境、家庭教养方式等。
            </span> </p>
          <p>医学干预：<span>结合各项评估，分析导致孩子学习困难问题的各项因素，制定个性化的干预方式，治标治本。
            </span> </p>
          <p>心理干预：<span>通过行为培养，改善成长环境，促进孩子在正向轻松地氛围中加速成长。
            </span> </p>
          <p>物理治疗：<span>针对疾病症状关联度高的特定脑区，通过长期反馈训练、重复电磁刺激等无痛无创无介入的方式，帮助恢复脑区的异常活动，快速缓解疾病。
            </span> </p>
          <p>复学日间照顾项目：<span>针对休学的学生开展日间复学项目，包括了医学及心理干预，疾病康复辅导，切实解决康复—回归之间的现实问题，和青少年及其家庭一起成长。
            </span> </p>
            <p>学习焦虑干预项目：<span>着重针对孩子的学习焦虑开展的心理项目，通过外部环境的改善，和学生学习认知，考试技巧的培养，帮助孩子改善学习焦虑，获得能力相对应的学习成绩。
            </span> </p>
            
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                    item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '单友荷', imgPath: 'export (13).png', memo: '擅长精神医学与心理卫生的临床、教学和科研工作达50余年，对成人和儿童各种精神疾病心理障碍和心理卫生问题的诊断和治疗具有丰富经验。', titleList: [{ id: 1, title: '四川大学华西医院心理卫生中心教授' }, { id: 2, title: '儿童精神病学知名专家' }] },

        { id: 2, name: '谢靓', imgPath: 'export (14).png', memo: '擅长青少年儿童情绪障碍的诊治、各类成年精神疾病的诊治，成瘾相关疾病的诊断和治疗。', titleList: [{ id: 1, title: '四川省预防医学会未成年人心理健康分会会员' }, { id: 2, title: '成都榕爱社会服务工作中心副理事长' }, { id: 3, title: '社会心理干预分会委员兼总干事' }] },

        { id: 3, name: '陈嫣', imgPath: 'export (8).png', memo: '母婴互动，产后情绪管理，幼儿认知情绪发展，幼儿沟通技能，亲子互动，家庭教育。以及儿童及青少年情绪管理，人际互动，亲子关系，压力管理，心理成长团体。', titleList: [{ id: 1, title: '中国科学院心理研究所硕士' }, { id: 2, title: '国家三级心理咨询师' }] },

        { id: 4, name: '李悠寒', imgPath: 'export (22).png', memo: '心理治疗师，国家三级心理咨询师。心理动力学、认知行为学受训背景。接受个人及团体体验270+小时，个人及团体督导70+小时，个案累计时长200+小时，团体心理咨询110+小时。擅长运用心理动力、认知行为、短程焦点等技术与来访者一同工作。', titleList: [{ id: 1, title: '心理治疗师，国家三级心理咨询师。' }, { id: 2, title: '心理动力学、认知行为学受训背景。' }] },
        // { id: 4, name: '李娜', imgPath: 'export (15).png', memo: '心理动力学、存在人本及认知行为（正念)、叙事绘画等心理学培训，并在执业过程中持续接受个人体验，以及团体和个人督导，个案累计时长1000+小时，团体辅导200+小时。', titleList: [{ id: 1, title: '华西医院心理卫生中心' }, { id: 2, title: '心理咨询师' }] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');
</style>