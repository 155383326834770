<template>
  <div class="index">
    <!-- 关系中心-亲子沟通单元 -->
    <div class="centreTitle">
      <p>关系中心-亲子沟通单元</p>
      <span>Relationship center</span>
    </div>
    <div>
      <div class="first">
        <div class="content">
          <span>
            人在社会中不是孤立的，人的存在是各种关系发生作用的结果，人正是通过和别人发生作用而发展自己，实现自己的价值。关系是生活中最有意义的方面之一，是深刻满足的源泉。在关系中心，我们讨论三个一体：亲密关系、人际关系和亲子关系。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>亲子沟通单元</p>
          <span>Parent-child communication unit</span>
        </div>
        <div class="content">
          <span>
            亲子关系是儿童最早建立起来的人际关系。父母的人品，对子女的教养、抚养、教育方式以及态度等，都在这种关系中直接对孩子的身心发展产生影响，也将影响儿童今后的人际交往关系。亲子关系是个体和社会生活中重要的一部分，在幼儿期，它几乎是个体全部情感的依赖所在。父母与子女之间的一种双向作用的人际关系。儿童在父母的抚养下成长，同时儿童身心发展又影响着父母的行为。亲子关系单元重点讨论家庭教育，亲子之间的关系，亲子之间的沟通和表达。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                    item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '臧肖', imgPath: 'export (7).png', memo: '擅长团体心理辅导，儿童青少年情绪问题、亲子关系咨询。', titleList: [{ id: 1, title: '博士、副教授、社会工作师、国家二级心理咨询师' }, { id: 2, title: '成都市溶爱工作服务中心副理事长' }, { id: 3, title: '四川省儿童工作资源中心专家库成员' }] },

        { id: 2, name: '孙璐', imgPath: 'export (9).png', memo: '个人自我探索和个人成长，青少年心理健康教育和咨询、家庭婚恋心理咨询，团体心理咨询。', titleList: [{ id: 1, title: '高校教师、副教授' }, { id: 2, title: '国家二级心理咨询师、心理学硕士' }, { id: 3, title: '四川省西部精神医学协会专业委员会委员' }] },

        { id: 3, name: '陈嫣', imgPath: 'export (8).png', memo: '母婴互动，产后情绪管理，幼儿认知情绪发展，幼儿沟通技能，亲子互动，家庭教育。以及儿童及青少年情绪管理，人际互动，亲子关系，压力管理，心理成长团体。', titleList: [{ id: 1, title: '中国科学院心理研究所硕士' }, { id: 2, title: '国家三级心理咨询师' }] },
        
        { id: 4, name: '李悠寒', imgPath: 'export (22).png', memo: '心理治疗师，国家三级心理咨询师。心理动力学、认知行为学受训背景。接受个人及团体体验270+小时，个人及团体督导70+小时，个案累计时长200+小时，团体心理咨询110+小时。擅长运用心理动力、认知行为、短程焦点等技术与来访者一同工作。', titleList: [{ id: 1, title: '心理治疗师，国家三级心理咨询师。' }, { id: 2, title: '心理动力学、认知行为学受训背景。' }] },
        // { id: 4, name: '刘萍', imgPath: 'export (4).png', memo: '擅长情绪管理，抑郁/焦虑/强迫等相关情绪障碍；亲密关系，亲子关系，人际关系，夫妻关系，恋人关系；学习焦虑，同学交往，童年情绪障碍等。', titleList: [{ id: 1, title: '国家注册心理咨询师' }, { id: 2, title: '情绪管理师' }, { id: 3, title: '父母教养指导师' }] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');

.index {
  .first {
    margin-top: 15px;
  }
}
</style>