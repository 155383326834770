<template>
  <div class="index">
    <!-- 关系中心-人际关系单元-->
    <div class="centreTitle">
      <p>关系中心-人际关系单元</p>
      <span>Relationship center</span>
    </div>
    <div>
      <div class="first">
        <div class="content">
          <span>
            人在社会中不是孤立的，人的存在是各种关系发生作用的结果，人正是通过和别人发生作用而发展自己，实现自己的价值。关系是生活中最有意义的方面之一，是深刻满足的源泉。在关系中心，我们讨论三个一体：亲密关系、人际关系和亲子关系。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>人际关系单元</p>
          <span>Interpersonal relationship unit</span>
        </div>
        <div class="content">
          <span>
            人际关系（Interpersonal relationships）是指人与人之间，在一段过程中，彼此借由思想、感情、行为所表现的吸引、排拒、合作、竞争、领导、服从等互动之关系。人际关系渗透到社会关系的各个方面之中，是社会关系的“横断面”，因而又反过来影响社会关系。在人际关系单元，我们讨论朋友关系、同学关系、师生关系以及职场中的各种关系。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                    item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '刘悦', imgPath: 'export (10).png', memo: '运用精神分析进行情绪、婚姻家庭、人家关系、亲子关系、自我成长以及职场问题的心理咨询。', titleList: [{ id: 1, title: '四川大学应用心理学硕士' }, { id: 2, title: '国家二级心理咨询师' }, { id: 3, title: '美国NGH注册催眠师' }] },

        // { id: 2, name: '张丽芳', imgPath: 'export (2).png', memo: 'LGBT的情绪困扰及亲密关系咨询；儿童及青少年情绪困惑、青春期心理适应；成年人抑郁、焦虑、失眠困惑、压力管理，个人成长；婚姻家庭咨询：亲子关系、亲密关系，情感困惑、原生家庭影响。', titleList: [{ id: 1, title: '国家二级心理咨询师' }, { id: 2, title: '中国科学院心理研究所研究生' }] },
        { id: 2, name: '毛晓雨', imgPath: 'export (11).png', memo: '擅长运用认知行为治疗技术、绘画治疗技术、沙盘治疗技术及短程焦点治疗，为来访者提供成长和帮助。', titleList: [{ id: 1, title: '心理医师，应用学心理硕士' }, { id: 2, title: '成都朗德教育心理成长专家' }, ] },

        { id: 3, name: '李扬', imgPath: 'export (21).png', memo: '以青少年为主的各类情绪问题（焦虑、抑郁、强迫等）、性心理障碍、人际关系、以及个人成长等方面的个体咨询及家庭治疗。', titleList: [{ id: 1, title: '心理治疗师、成都医学院心理学院讲师' }, { id: 2, title: '四川省心理学会会员' } , {id: 3, title: '四川省医学传播学会儿童青少年心理健康分会会员'}] },

        { id: 4, name: '刘萍', imgPath: 'export (4).png', memo: '擅长情绪管理，抑郁/焦虑/强迫等相关情绪障碍；亲密关系，亲子关系，人际关系，夫妻关系，恋人关系；学习焦虑，同学交往，童年情绪障碍等。', titleList: [{ id: 1, title: '国家注册心理咨询师' }, { id: 2, title: '情绪管理师' }, { id: 3, title: '父母教养指导师' }] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');

.index {
  .first {
    margin-top: 15px;
  }
}
</style>