<template>
    <div v-if="!$store.state.isPhone" class="case">
        <!-- <div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
                <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
                <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
                <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
            </el-tabs>
        </div> -->
        <div class="left">
            <div class="item" v-for="item in caseList" :key="item.id" @click="checkCentre(item.id)">
                <p :class="item.id === activeId ? 'active' : ''">{{ item.name }}</p>
                <span></span>
            </div>
        </div>
        <div class="right">
            <div class="top">
                <span @click="checkCase(item.id)" :class="item.id === activeSpan ? 'activeSpan' : ''"
                    v-for="item in caseData" :key="item.id">{{
                        item.title }}</span>
                <!-- <span>案例2</span>
                <span>案例3</span> -->
            </div>
            <div class="contentText">
                <!-- <p class="title">动机：我为什么帮助不了他？</p>
                <p class="title title2">一个非常具有挑战性的病例。</p> -->
                <!-- <img src="/api/1.jpg" alt=""> -->
                <p class="text" v-html="activeContent">

                </p>
            </div>
        </div>
    </div>
    <div class="case phone" v-else>
        <el-tabs style="width:100%" type="card" v-model="activeName" @tab-click="checkCentre">
            <el-tab-pane :label="item.name" class="item" v-for="item in caseList" :key="item.id" 
                :name="item.name">
                <div class="right">
                <div class="top">
                <span @click="checkCase(item.id)" :class="item.id === activeSpan ? 'activeSpan' : ''"
                    v-for="item in caseData" :key="item.id">{{
                        item.title }}</span>
            </div>
            <div class="contentText">
                <p class="text" v-html="activeContent">

                </p>
            </div>
            </div></el-tab-pane>

        </el-tabs>
    </div>
</template>

<script>
import { newsListAPI } from '@/api/news'
export default {
    data() {
        return {
            activeName: '成瘾中心',
            // isPc: true,
            caseList: [
                { id: 1, name: '成瘾中心' }, { id: 2, name: '睡眠中心' }, { id: 3, name: '关系中心—亲密关系单元' }, { id: 4, name: '关系中心—亲子沟通单元' }, { id: 5, name: '关系中心—人际关系单元' }, { id: 6, name: '个人成长中心' }, { id: 7, name: '学习困难中心' }, { id: 8, name: '情绪障碍中心' }, { id: 9, name: '认知障碍中心' }
            ],
            caseData: [],
            activeId: 1,
            activeSpan: 1,
            activeContent: '', //当前案例内容
        }
    },
    methods: {
        // 获取所有案例
        async getList() {
            // let time = Date.now();
            let info = {
                categoryId: Number(this.$store.state.articleAllType.addictionType),
                // pageNumber: this.paging.pageNumber,
                // pageSize: this.paging.pageSize,
                // orderBy:'created asc'
            }
            if (this.activeId === 2 || this.activeName === '睡眠中心') {
                info.categoryId = Number(this.$store.state.articleAllType.sleepType)
            } else if (this.activeId === 3 || this.activeName === '关系中心—亲密关系单元') {
                info.categoryId = Number(this.$store.state.articleAllType.intimacyType)
            } else if (this.activeId === 4 || this.activeName === '关系中心—亲子沟通单元') {
                info.categoryId = Number(this.$store.state.articleAllType.communicationType)
            } else if (this.activeId === 5 || this.activeName === '关系中心—人际关系单元') {
                info.categoryId = Number(this.$store.state.articleAllType.interpersonalRelationshipType)
            } else if (this.activeId === 6 || this.activeName === '个人成长中心') {
                info.categoryId = Number(this.$store.state.articleAllType.growType)
            } else if (this.activeId === 7 || this.activeName === '学习困难中心') {
                info.categoryId = Number(this.$store.state.articleAllType.learnType)
            } else if (this.activeId === 8 || this.activeName === '情绪障碍中心') {
                info.categoryId = Number(this.$store.state.articleAllType.emotionType)
            } else if (this.activeId === 9 || this.activeName === '认知障碍中心') {
                info.categoryId = Number(this.$store.state.articleAllType.cognitionType)
            }
            const res = await newsListAPI(info);
            console.log(res);
            if (res.state === 'ok' && res.page) {
                // console.log(res.page)
                this.caseData = res.page.list
                if (this.caseData.length > 0) {
                    this.activeSpan = this.caseData[0].id;
                    this.caseData.forEach(item => {
                        if (item.id === this.activeSpan) {
                            this.activeContent = item.content
                        }
                    })
                }
                // this.paging.total = res.page.totalRow
            }
        },
        // 切换案例
        checkCase(val) {

            this.activeSpan = val;
            this.caseData.forEach(item => {
                if (item.id === this.activeSpan) {
                    this.activeContent = item.content
                }
            })
        },
        // 切换中心
        checkCentre(val) {
            console.log(val);
            this.activeId = val;
            this.activeContent = ''
            // sessionStorage.setItem('centreId', val);
            // location.reload();
            this.getList();
            // window.
        }

    },
    created() {
        
        // this.isPc = sessionStorage.getItem('isPc')
        this.activeId = Number(sessionStorage.getItem('centreId'));
        if (this.activeId === 2  ) {
            this.activeName = '睡眠中心'
            } else if (this.activeId === 3 ) {
                this.activeName = '关系中心—亲密关系单元'
            } else if (this.activeId === 4 ) {
                this.activeName = '关系中心—亲子沟通单元'
            } else if (this.activeId === 5 ) {
                this.activeName = '关系中心—人际关系单元'
            } else if (this.activeId === 6 ) {
                this.activeName = '个人成长中心'
            } else if (this.activeId === 7 ) {
                this.activeName = '学习困难中心'
            } else if (this.activeId === 8 ) {
                this.activeName = '情绪障碍中心'
            } else if (this.activeId === 9 ) {
                this.activeName = '认知障碍中心'
            }
        this.getList();
    }
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');

.left {
    .item {
        cursor: pointer;
    }
}

.case.phone {
    flex-wrap: wrap;

    .left {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        align-content: center;
        overflow: hidden;
        overflow-x: scroll;

        .item {
            // flex-shrink: 0;
            // width: 100px;
            margin-top: 0;
        }
    }
    .right{
        width: 90%;
        // border-top: 0px;
        padding: 20px 10px;
    }

}

@media screen and (max-width: 600px) {
    .case.phone {
        // display: block !important;
        // .left{
        //     display: flex;
        //     flex-wrap: wrap;
        //     align-content: center;
        //     .item{
        //         flex-shrink: 0;
        //     }
        // }

        .right {
            width: 100% !important;
            box-sizing: border-box;
            margin-left: 0px !important;
        }
    }
}
</style>