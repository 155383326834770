<template>
  <div class="index">
    <!-- 成瘾中心 -->
    <div class="centreTitle">
      <p>情绪障碍中心</p>
      <span>Mood disorders center</span>
    </div>
    <div>
      <div class="Prompt">
        <p>中心介绍</p>
        <span>Center introduction</span>
      </div>
      <div class="content">
        <span>
          情绪障碍中心专注于解决各个年龄层次人群的情绪障碍问题。包括了抑郁发作和双相情感障碍，各类焦虑障碍，心身障碍、进食障碍等。结合国内外诊疗指南，循证医学证据和专家数十年临床治疗经验，针对患者的症状表现，结合患者的成长环境、家庭、社会支持系统等开展全面的评估，严谨、细致、科学地处理情绪问题。
        </span>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>服务介绍</p>
        <span>Service introduction</span>
      </div>
      <div class="content">
        <p>评估检查：<span>专业医师团队对情绪问题开展详细的问诊和评估，了解情绪问题的症状表现，患者的成长环境、家庭、社会支持系统等。
          </span> </p>
          <p>医学干预：<span>结合各项评估，分析导致孩子情绪问题的各项因素，制定个性化的干预方式，治标治本。
          </span> </p>
          <p>心理干预：<span>通过心理咨询与治疗，改进对于情绪问题的不合理认知，提高改善情绪问题的技能和方法。
          </span> </p>
          <p>物理治疗：<span>针对疾病症状关联度高的特定脑区，通过长期反馈训练、重复电磁刺激等无痛无创无介入的方式，帮助恢复脑区的异常活动，快速缓解疾病。
          </span> </p>
          <p>复学日间照顾项目：<span>针对休学的学生开展日间复学项目，包括了医学及心理干预，疾病康复辅导，切实解决康复—回归之间的现实问题，和青少年及其家庭一起成长。
          </span> </p>
          <p>学习焦虑干预项目：<span>着重针对孩子的学习焦虑开展的心理项目，通过外部环境的改善，和学生学习认知，考试技巧的培养，帮助孩子改善学习焦虑，获得能力相对应的学习成绩。
          </span> </p>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>专家团体</p>
        <span>Expert introduction</span>
      </div>
      <div>
        <div class="export">
          <div class="item" v-for="item in exportList" :key="item.id">
            <div class="left">
              <span>{{ item.name }}</span>
              <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
              <div v-else class="noImg"></div>
            </div>
            <div class="right">
              <div class="title">
                <p v-for="i in
                item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
              </div>
              <p>{{ item.memo }}</p>
            </div>
            <div class="bottomStyle"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="Prompt">
        <p>案例介绍</p>
        <span>Case introduction</span>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return{
      exportList: [
      { id: 1, name: '朱昌明', imgPath: 'export (5).png', memo: '擅长各种情绪、睡眠、行为障碍-集婚恋、家庭、老年、中青年心理健康问题的诊疗、咨询、心理评估。', titleList: [{ id: 1, title: '四川大学华西医院心理卫生中心' }, { id: 2, title: '终身教授' }, { id: 3, title: '著名精神病学专家' }] },

        {id: 2, name: '胡珉', imgPath: 'export (1).png', memo: '擅长青少年儿童情绪障碍的诊治、各类成年精神疾病的诊治，成瘾相关疾病的诊断和治疗。', titleList: [{id:1, title: '医学硕士'}, {id:2, title: '精神科副主任医师'}, {id: 3, title: '心理治疗师'}]},
        { id: 4, name: '余良明', imgPath: 'export (19).png', memo: '擅长成人目标导向的心理咨询、精神疾病康复、焦虑抑郁的分析和治疗。尤其是青少年儿童成长的心理咨询，行为问题的诊疗。LGBT友好。', titleList: [{ id: 1, title: '医学硕士' }, { id: 2, title: '精神科医师' }, { id: 3, title: '心理治疗师' }, 
        { id: 4, title: '心理咨询师' },] },
        //  { id: 3, name: '余良明', imgPath: 'export (20).png', memo: '擅长目标导向的心理咨询、精神疾病康复、焦虑抑郁的分析与治疗、青少年成长及性心理咨询。', titleList: [{ id: 1, title: '性少数友好咨询师' }, { id: 2, title: '心理咨询师' }] },
        {id: 4, name: '王洲兰', imgPath: 'export (16).png', memo: '儿童及青少年情绪、行为问题，青春期心理适应，亲子关系；性少数人群情绪困扰；亲密关系困扰及伴侣咨询；成年人焦虑、抑郁情绪；个人成长。', titleList: [{id:1, title: '国家二级心理咨询师'}, {id:2, title: '催眠与心理健康专业委员会秘书长'}, {id: 3, title: '北京师范大学发展与教育心理学硕士'}]},
      ],
      
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');
</style>