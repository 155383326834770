<template>
  <div class="index">
    <!-- 亲密关系 -->
    <div class="centreTitle">
      <p>关系中心-亲密关系单元</p>
      <span>Relationship center</span>
    </div>
    <div>
      <div class="first">
        <div class="content">
          <span>
            人在社会中不是孤立的，人的存在是各种关系发生作用的结果，人正是通过和别人发生作用而发展自己，实现自己的价值。关系是生活中最有意义的方面之一，是深刻满足的源泉。在关系中心，我们讨论三个一体：亲密关系、人际关系和亲子关系。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>亲密关系单元</p>
          <span>Intimate relationship unit</span>
        </div>
        <div class="content">
          <span>
            亲密关系意味着一种主体体验到的情感或身体上的亲近。在个人化的现代社会里，注重情感的坦白和沟通，相互间的深知和了解是现代亲密关系的核心，同时也反映了人类对自由和安全的矛盾需求。在亲密关系单元，家庭、婚姻、恋爱、性爱等关系都是我们讨论的范畴。
          </span>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>专家团体</p>
          <span>Expert introduction</span>
        </div>
        <div>
          <div class="export">
            <div class="item" v-for="item in exportList" :key="item.id">
              <div class="left">
                <span>{{ item.name }}</span>
                <img v-if="item.imgPath" :src="require(`../../../assets/images/service/centre/${item.imgPath}`)" alt="">
                <div v-else class="noImg"></div>
              </div>
              <div class="right">
                <div class="title">
                  <p v-for="i in
                    item.titleList" :key="i.id"><span class="icon"></span>{{ i.title }}</p>
                </div>
                <p>{{ item.memo }}</p>
              </div>
              <div class="bottomStyle"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="Prompt">
          <p>案例介绍</p>
          <span>Case introduction</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exportList: [
        { id: 1, name: '高哲', imgPath: 'export (3).png', memo: '擅长精神障碍的诊治，以及焦虑抑郁等情绪问题的咨询。', titleList: [{ id: 1, title: '医师硕士' }, { id: 2, title: '精神科主治医生' }, { id: 3, title: '心理治疗师' }] },

        { id: 2, name: '徐榕婧', imgPath: 'export (6).png', memo: '擅长家庭咨询、婚前辅导、亲子关系、个体情绪障碍和内在动作模式探索、创伤辅导。', titleList: [{ id: 1, title: '国家二级心理咨询师' }, { id: 2, title: 'EFT(情绪取向)婚姻家庭' }, { id: 3, title: '个体治疗师认证培训治疗师' }] },

        { id: 3, name: '吴颖', imgPath: 'export (18).png', memo: '擅长青少年情绪和亲密关系，以及女性成长。', titleList: [{ id: 1, title: '四川大学应用心理学硕士' }, { id: 2, title: '国家二级心理咨询师' }, { id: 3, title: '成都某高校心理专业教师' }] },

        { id: 4, name: '刘萍', imgPath: 'export (4).png', memo: '擅长情绪管理，抑郁/焦虑/强迫等相关情绪障碍；亲密关系，亲子关系，人际关系，夫妻关系，恋人关系；学习焦虑，同学交往，童年情绪障碍等。', titleList: [{ id: 1, title: '国家注册心理咨询师' }, { id: 2, title: '情绪管理师' }, { id: 3, title: '父母教养指导师' }] },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
@import url('../../../assets/css/centre.css');

.index {
  .first {
    margin-top: 15px;
  }
}
</style>