<template>
  <div class="centre">
    <!-- 各个中心 -->
    <Relationship v-if="nowComponents === 5"></Relationship>
    <Addiction v-if="nowComponents === 1"></Addiction>
    <Cognition v-if="nowComponents === 9"></Cognition>
    <Communication v-if="nowComponents === 4"></Communication>
    <Difficulty v-if="nowComponents === 7"></Difficulty>
    <Emotion v-if="nowComponents === 8"></Emotion>
    <Grow v-if="nowComponents === 6"></Grow>
    <Intimacy v-if="nowComponents === 3"></Intimacy>
    <Sleep v-if="nowComponents === 2"></Sleep>
    <div class="Case">
      <AllCase></AllCase>
    </div>
  </div>
</template>

<script>
import Relationship from './Relationship.vue';
import Addiction from './Addiction.vue'
import Cognition from './Cognition.vue'
import Communication from './Communication.vue'
import Difficulty from './Difficulty.vue'
import Emotion from './Emotion.vue'
import Grow from './Grow.vue'
import Intimacy from './Intimacy.vue'
import Sleep from './Sleep.vue'
import AllCase from './AllCase.vue';


export default {
  components: { Relationship, Addiction, Cognition, Communication, Difficulty, Emotion, Grow, Intimacy, Sleep, AllCase },
  data() {
    return {
      nowComponents: 1
    }
  },
  methods: {
    
  },
  created() {
    this.nowComponents = Number(sessionStorage.getItem('centreId'));
    
    // this.getList();
    // console.log(this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
.centre {
  padding-top: 30px;

  .Case {
    display: flex;
    /* flex: ; */
  }
}
</style>